import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Text,
} from "@chakra-ui/react"
import ServicesHeroImage from "components/images/ServicesHeroImage"
import Footer from "components/layout/Footer"
import Header from "components/layout/Header"
import LayoutBase from "components/layout/LayoutBase"
import SEO from "components/layout/SEO"
import Link from "components/ui/Link"
import MaxWidthWrapper from "components/ui/MaxWidthWrapper"
import { PageProps } from "gatsby"
import React from "react"
import { FaCloud, FaRandom, FaReact } from "react-icons/fa"
import { GiMagnifyingGlass } from "react-icons/gi"
import { RiBarChartFill } from "react-icons/ri"
import { SiKubernetes } from "react-icons/si"

const SERVICES_DATA = [
  {
    title: "Research",
    icon: GiMagnifyingGlass,
    desc: "Find more efficient ways to conduct your business by leveraging new technologies",
  },
  {
    title: "Cloud Solutions",
    icon: FaCloud,
    desc: "Move your business application to a cloud or create a cloud-native app from scratch",
  },
  {
    title: "Data Pipelines",
    icon: FaRandom,
    desc: "Transform your data into actionable reports and statistics",
  },
  {
    title: "Data Visualization",
    icon: RiBarChartFill,
    desc: "Visualize your data on a dashboard or in the form of reports",
  },
  {
    title: "Microservices",
    icon: SiKubernetes,
    desc: "Add new services to your existing stack in the language and framework of your choice",
  },
  {
    title: "Interface",
    icon: FaReact,
    desc: "Add a clean web interface to your application written in the battle-tested React framework",
  },
]
// GiMagnifyingGlass
const Services = (props: PageProps) => {
  return (
    <LayoutBase>
      <SEO title="MN: Services" />
      <Header
        location={props.location}
        position="static"
        color="black"
        backgroundColor="secondary.300"
        logoProps={{ color: "white" }}
        top={0}
        left={0}
        right={0}
      />
      <Flex flex="1 1 auto" flexDirection="column">
        <Grid backgroundColor="primary.main">
          <ServicesHeroImage
            style={{
              gridArea: "1/1",
              maxHeight: "400px",
            }}
          />
          <Grid gridArea="1/1" position="relative" placeItems="center">
            <Box w="100%" backgroundColor="rgba(150,150,150,0.5)">
              <Heading
                as="h1"
                fontSize="7xl"
                color="white"
                fontWeight={300}
                textAlign="center"
              >
                Services
              </Heading>
            </Box>
          </Grid>
        </Grid>
        <MaxWidthWrapper pb={16}>
          <Grid
            templateColumns={["1fr", "1fr", "1fr 1fr"]}
            gridAutoRows="1fr"
            gap={4}
          >
            {/* <Text>
            As an experienced consultant I can provide you with various services
            that either supplement your existing IT department or create a tech
            solutions from scratch according to your needs. You don't have to
            possess expert knowledge, all you need is an idea, which we can
            discuss during a short online call. I will help you with translating
            your pitch into actionable technical specification. - system design
            I can design a system in cloud on a provider of your choice: Google,
            AWS, Azure, etc. - visual layer You will get a clean user interface
            for your application - extensible and documented The finished
            product can be improved and easily used by other people thanks to
            premeditated design patterns and extensive documentation -
            prototypes, Proof of concept You want to try a new, hot thing, but
            don't know where to start? I can help you out. I will create a demo
            for you and write a deployment roadmap, if you wish to move it to
            production. Programmer polyglot languages: - javascript - go - java
            - python What people say: - Mikolaj - Mariusz
          </Text> */}

            {SERVICES_DATA.map(data => (
              <GridItem
                display="flex"
                alignItems={["flex-start", "center"]}
                justifyContent="flex-start"
                p={4}
                key={data.title}
                bgColor="primary.50"
                boxShadow="0 0.125rem 0.25rem rgba(0, 0, 0, 0.15)"
                flexDir={["column", "row"]}
              >
                <Box display="flex" alignItems="center" flex="1 1 50%">
                  <Box bg="secondary.main" p={2} borderRadius="50%" mr={4}>
                    <Icon
                      as={data.icon}
                      color="white"
                      display="block"
                      boxSize={8}
                    />
                  </Box>
                  <Text>{data.title}</Text>
                </Box>
                <Divider
                  orientation="vertical"
                  display={["none", "block"]}
                  mx={3}
                />
                <Divider
                  orientation="horizontal"
                  display={["", "none"]}
                  my={3}
                />
                <Box flex="1 1 50%" p={1}>
                  <Text>{data.desc}</Text>
                </Box>
              </GridItem>
            ))}
          </Grid>
        </MaxWidthWrapper>

        <Flex
          w="100%"
          bgColor="secondary.700"
          flex="1 1 auto"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          minH="410px"
        >
          <Heading color="white" textAlign="center">
            Sounds interesting?{" "}
          </Heading>
          <Link
            mt={2}
            href="/contact"
            isGatsby={true}
            color="white"
            p={3}
            borderRadius="5px"
          >
            Contact me
          </Link>
        </Flex>
      </Flex>
      <Footer bgColor="secondary.700" />
    </LayoutBase>
  )
}

export default Services
